$(function () {
    var mAppbar = $('.ui.mobile.appbar'),
        mSlidemenu = $('.ui.slidemenu'),
        mSlidemenuToggle = mAppbar.find('.toggle .icon'),
        mSlidemenuDimmer = mSlidemenu.next('.ui.dimmer'),
        heroimage = $('.ui.heroimage');

    var lastScrollTop = 0,
        heroimageEndPosY = heroimage.position().top + heroimage.height();

    /**
     * Check if screensize fall into mobile
     * @return boolean Is mobile screen
     */
    function isMobile() {
        return $(window).innerWidth() <= 768;
    }

    /**
     * Animate slidemenu toggle icon
     */
    function animateToggleIcon() {
        mSlidemenuToggle.toggleClass('open');
    }

    /**
     * Hide appbar
     */
    function hideAppBar() {
        mAppbar.removeClass('shown');
    }

    /**
     * Show appbar
     */
    function showAppBar() {
        mAppbar.addClass('shown');
    }

    /**
     * Show/hide slidemenu dimmer
     */
    function toggleDimmer() {
        mSlidemenuDimmer.toggleClass('hidden');
        $('body').toggleClass('disable-scroll');
    }

    /**
     * Open/close slidemenu
     */
    function toggleSlidemenu() {
        mSlidemenu.toggleClass('open');
        animateToggleIcon();
        toggleDimmer();
    }

    /**
     * Event function on slidemenu dimmer
     */
    mSlidemenuDimmer.click(function () {
        if (isMobile()) {
            toggleSlidemenu();
        }
    });

    /**
     * Event function on slidemenu toggle clicked
     */
    mSlidemenuToggle.click(function () {
        if (isMobile()) {
            toggleSlidemenu();
        }
    });

    /**
     * Event function on slidemenu nav list items clicked
     */
    mSlidemenu.find('.nav.list .list .item').click(function () {
        if (isMobile()) {
            toggleSlidemenu();
        }
    });

    /**
     * Event function on window scrolled
     */
    $(window).scroll(function () {
        var _scrollTop = $(this).scrollTop();

        if (isMobile()) {
            if (_scrollTop >= heroimageEndPosY + 1) {
                // Downward scroll
                if (_scrollTop > lastScrollTop) {
                    if (_scrollTop >= 61) {
                        hideAppBar();
                    }
                }
                // Upward scroll
                else {
                    showAppBar();
                }
            }
        }

        lastScrollTop = _scrollTop;
    });

    if (!mAppbar.hasClass('shown')) {
        if (isMobile()) {
            showAppBar();
        }
    }
});